import React, { useState, useEffect } from "react";
import { MDBAnimation } from "mdbreact";
import BigTitle from "../../Components/BigTitle";
import "./MadUpPro.scss";
import * as dataJSON from "../../Data/MadUpPro.json";
import { withRouter, Link } from "react-router-dom";

import Meta from "../../Components/Meta";

const MadUpPro = (props) => {
    let lang = props.match.params.lang;
    const [data, setData] = useState(dataJSON.default.fr);

    const [meta, setMeta] = useState({
        title: dataJSON.default[lang].meta.title,
        description: dataJSON.default[lang].meta.description,
        canonical: dataJSON.default[lang].meta.canonical,
        image: dataJSON.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: dataJSON.default[lang].meta.keywords,
            }
           
        },
    }); 
    const scrollToTop = () => window.scrollTo(0, 0);
    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [lang]);
    return (
        <>
         <Meta meta={meta}/>
            <MDBAnimation type="fadeIn">               
                <section id="activation" className="container ">
                <BigTitle
                    text={data.activation.bigtitle.text}
                    title={data.activation.bigtitle.title}
                    span={data.activation.bigtitle.span}
                    direct="left"
                />
                <div className="update">
                <p>{data.activation.text}
                
                </p>
                <p>{data.activation.text2}
                &nbsp;
                <Link
                       to={`/${lang}${data.activation.SLUG}`}
                       onClick={scrollToTop()}
                       style={{textDecoration:"underline"}}
                   >
                     
                      {data.activation.textSlug}
                   </Link>
                </p>
                </div>
                
               
                    </section>
                
            </MDBAnimation>
        </>
    );
};

export default withRouter(MadUpPro);
