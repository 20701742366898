import React, { useEffect, Suspense } from "react";
import "./TooltipsBonhomme.scss";
import Tooltip from "./TooltipBonhomme";
import 'react-multi-carousel/lib/styles.css';
import BigTitle from "../BigTitle";
import { withRouter } from "react-router-dom";
import { gsap } from "gsap";
import ScrollEffect from "../ScrollEffect/ScrollEffect";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Modal from "./Modal";

gsap.registerPlugin(ScrollTrigger);


const TooltipsBonhomme = (props) => {
  /*** Différentes configurations de ScrollTrigger pour différentes tailles d'écran (media queries) ***/

  useEffect(() => {
    ScrollTrigger.matchMedia({

      // desktop
      "(min-width: 768px)": function () {
        // stopper  l'animations et le ScrollTriggers pour les ecrans plus 1100px px (desktop)
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: ".layer-content",
            scrub: true,
            pin: true,
            start: "center 55%",
            end: "2600px",
            pinspacer: true,
            markers: false,
          }
        });

        tl.from(
          ".Block",
          { y: 0, autoAlpha: 0, stagger: 1 }, 0)
          .to(
            ".Block",
            {
              y: 0,
              autoAlpha: 0,
              stagger: 1



            },
            1
          );
        tl.from(
          ".tooltip_circle",
          { y: 0, autoAlpha: 0, stagger: 1 },
          0
        ).to(
          ".tooltip_circle",
          {
            y: 0,
            autoAlpha: 0,
            stagger: 1,



          },
          0.5
        );
        tl.from(
          ".color",
          { y: 0, autoAlpha: 0, stagger: 1 },
          0
        ).to(
          ".color",
          {
            y: 0,
            autoAlpha: 0,
            stagger: 1,
          },
          1
        );
      },
      // mobile
      "(max-width: 767px)": function () {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: ".layer-content-mobile",
            scrub: true,
            pin: true,
            start: "top 0",
            end: "1200px",
            pinspacer: true,
            markers: false,
          }
        });

        tl.from(
          ".Block",
          { y: 0, autoAlpha: 0, stagger: 1 }, 0)
          .to(
            ".Block",
            {
              y: 0,
              autoAlpha: 0,
              stagger: 1
            },
            1
          );
        tl.from(
          ".tooltip_circle",
          { y: 0, autoAlpha: 0, stagger: 1 },
          0
        ).to(
          ".tooltip_circle",
          {
            y: 0,
            autoAlpha: 0,
            stagger: 1,
          },
          0.5
        );
        tl.from(
          ".color",
          { y: 0, autoAlpha: 0, stagger: 1 },
          0
        ).to(
          ".color",
          {
            y: 0,
            autoAlpha: 0,
            stagger: 1,
          },
          1
        );
      }

    });
  }, []);
  const lang = props.match.params.lang;
  const data = props.data;


  return (
    <>
     
      <div className="layer-content-mobile">
        <BigTitle
          text={data.bigtitle.text}
          title={data.bigtitle.title}
          span={data.bigtitle.span}
          direct="left"
        />
      
        <div className="layer-content">
       
            <div className="tools bg-blue ">
           
            <img src={data.coverImage} alt="bonhomme 3d" className="imgBonhomme" />
          
              {data.view.map(({ id}) => {
                return (
                  <Tooltip
                    key={id}
                    id={id}
                   

                  />
                );
              })}
              
              <div className="card-titleBonhomme">{data.bigtitle.span}
              </div>

              <ScrollEffect />
            
              <div className="Block" id="01">
                <div className="tools">
                  <div className="tool ">
                    <div className="tooltip_block">
                      <Modal slug={data.view[0].slug} text={data.view[0].textSpan}
                       src={`${data.view[0].src}/${lang}`} title={data.view[0].title} span={data.view[0].span}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Block" id="02">
                <div className="tools">
                  <div className="tool ">
                    <div className="tooltip_block2">
                      <Modal slug={data.view[1].slug} text={data.view[1].textSpan} 
                      src={`${data.view[1].src}/${lang}`} title={data.view[1].title} span={data.view[1].span}/>
                    </div>
                  </div>
                </div>
              </div>

              <div className="Block" id="03">
                <div className="tools">
                  <div className="tool">
                    <div className="tooltip_block3">
                      <Modal slug={data.view[2].slug} text={data.view[2].textSpan} 
                      src={`${data.view[2].src}/${lang}`} title={data.view[2].title} span={data.view[2].span}/>
                    </div>
                  </div>
                </div>
              </div>

              <div className="Block" id="04">
                <div className="tools">
                  <div className="tool">
                    <div className="tooltip_block4">
                      <Modal slug={data.view[3].slug} text={data.view[3].textSpan} 
                      src={`${data.view[3].src}/${lang}`} title={data.view[3].title} span={data.view[3].span}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Block" id="06">
                <div className="tools">
                  <div className="tool">
                    <div className="tooltip_block6 ">
                      <Modal slug={data.view[5].slug} text={data.view[5].textSpan} 
                      src={`${data.view[5].src}/${lang}`} title={data.view[5].title} span={data.view[5].span} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="Block" id="05">
                <div className="tools">
                  <div className="tool">
                    <div className="tooltip_block5">
                      <Modal slug={data.view[4].slug} text={data.view[4].textSpan} 
                      src={`${data.view[4].src}/${lang}`} title={data.view[4].title} span={data.view[4].span} />
                    </div>
                  </div>
                </div>
              </div>
             
              <div className="navmenu">
              
                <span className="hr"></span>
                
                <div className="circlestep">
                  <span className="color"></span>
                  <div className="circlestep-01"><span className="color"></span></div>
                  <div className="circlestep-01"><span className="color"></span></div>
                  <div className="circlestep-01"><span className="color"></span></div>
                  <div className="circlestep-01"><span className="color"></span></div>
                  <div className="circlestep-01"><span className="color"></span></div>
                  {/* <div className="circlestep-01"><span className="color"></span></div>
                  <div className="circlestep-01"><span className="color"></span></div> */}
               
                </div>
                
              </div>
              
            </div>
         
           
        </div>
       
      </div>


    </>
  );
};
export default withRouter(TooltipsBonhomme);
