import React, { useState, useEffect } from "react";
import "./Fonctionnement.scss";
import { withRouter } from "react-router-dom";
import * as dataJSON from "../../Data/MadUpPhysio.json";
import ModalVideoProduit from "../ModalVideoProduit/ModalVideoProduit";

window.currentCircleRotation = 0;
let path = window.currentCircleRotation;
window.addEventListener("DOMContentLoaded", () => {
    path = 0;
});

const Fonctionnement = (props) => {
    let lang = props.match.params.lang;
    const [data, setData] = useState(dataJSON.default[lang]);
    const[srcImage,setSrcImage]=useState(data.roundimg)
    const [deg, setDeg] = useState({
        top: "14%",
        right: "14%",
        bottom: "auto",
        left: "auto",
    });
    useEffect(() => {
        setData(dataJSON.default[lang]);
    }, [lang]);

    const circle = {
        transform: deg.transform,
    };
  
    const changeImage= (deg)=>{
        if (deg===315){
            setSrcImage("/image/home.png");
           // document.getElementById("img").src="https://mad-up.com/api/uploads/mad-up/originals/analyseDesDonnees.png";
        }
        if( deg===275){
            setSrcImage("/image/metronome.png")
           // document.getElementById("img").src="https://mad-up.com/api/uploads/mad-up/originals/d8f65657-cb3c-4f16-bb4d-33e6737789cf.jpg";
        }
        if( deg===225){
            setSrcImage("/image/progs.png");
            //document.getElementById("img").src="https://mad-up.com/api/uploads/mad-up/originals/protocoleDeTravail.png";
        }
        if( deg===135){
            setSrcImage("/image/brevet.png");
           // document.getElementById("img").src="https://mad-up.com/api/uploads/mad-up/originals/technologieBreveteProd.png";
        }
        if( deg===180){
            setSrcImage("/image/stats.png");
           // document.getElementById("img").src="https://mad-up.com/api/uploads/mad-up/originals/parfaitementIntuitive.png";
        }
      

    }
    const setEffects = (deg) => {
        const currentRelativeSpin = window.currentCircleRotation % 360;
        let curentDeg = deg;
        let distanceRight = curentDeg - currentRelativeSpin;
        const distanceLeft = (360 - distanceRight) % 360;

        if (deg === 315 && path === 315) {
            path -= distanceLeft;
            
        }

        if (currentRelativeSpin > 180 && curentDeg === 0) {
            curentDeg = 360;
        }

        if (distanceRight > distanceLeft) {
            path -= distanceLeft;
        } else {
            path += distanceRight;
        }

        window.currentCircleRotation = path;

        setDeg({
            transform: `rotate(${window.currentCircleRotation}deg)`,
        });
    };

    return (
        <>
            <section className="fonctionnement">
               
                <div className="container">
               
                    <div className="fonctionnement_left">
                    
                            <div
                                className="fonctionnement_item"
                                onMouseEnter={() => {
                                    setEffects(315);
                                    changeImage(315);
                                }}
                            >
                               
                                <div className="fonctionnement_title">
                                    {data.item[0].title}
                                </div>
                                <div className="fonctionnement_text">
                                    {/* <ul>
                                    {data.item[0].text.map((text, id)=>
                                        <li key={id}>{text}</li>
                                        )}
                                    </ul> */}
                                    {data.item[0].textPS}
                                </div>
                               
                                {/* <ModalVideoProduit poster={data.item[0].poster} src={data.item[0].src} button_text= {data.button_text}/> */}
                            </div>
                      
                            <div
                                className="fonctionnement_item"
                                onMouseEnter={() => {
                                    setEffects(275);
                                    changeImage(275);
                                    
                                }}
                            >
                               
                                <div className="fonctionnement_title">
                                    {data.item[5].title}
                                </div>
                                <div className="fonctionnement_text">
                                    <ul>
                                    {data.item[5].text.map((text,id)=>
                                        <li key={id}>{text}</li>
                                        )}
                                    </ul>
                                </div>
                                {/* <ModalVideoProduit poster={data.item[5].poster} src={data.item[5].src} button_text= {data.button_text}/> */}
                            </div>
                      
                    </div>
                    <div className="fonctionnement_center">
                        <div id="round">
                            <div id="circle" style={circle}>
                                <div id="subcircle"></div>
                               
                            </div>
                            <img src={srcImage} alt="" className="fonctionnement_center_image" id="img"/>
                           
                         </div>

                            <div
                                className="fonctionnement_item"
                                onMouseEnter={() => {
                                    setEffects(225);
                                    changeImage(225);
                                }}
                            >
                                
                                <div className="fonctionnement_title">
                                    {data.item[3].title}
                                </div>
                                <div className="fonctionnement_text">
                                   
                                   { data.item[3].text}
                
                                   
                                </div>
                                {/* <ModalVideoProduit src={data.item[2].src} button_text= {data.button_text}/> */}
                            </div>
                      
                    </div>

                    <div className="fonctionnement_right">

                            <div
                                className="fonctionnement_item"
                                onMouseEnter={() => {
                                    setEffects(135);
                                    changeImage(135)
                                }}
                            >
                              
                                <div className="fonctionnement_title">
                                    {data.item[2].title}
                                </div>
                                <div className="fonctionnement_text">
                                    { data.item[2].text}
                                </div>
                                {/* <ModalVideoProduit poster={data.item[3].poster} src={data.item[3].src} button_text= {data.button_text}/> */}
                            </div>
                      
                            <div
                                className="fonctionnement_item"
                                onMouseEnter={() => {
                                    setEffects(180);
                                    changeImage(180)
                                }}
                            >
                              
                                <div className="fonctionnement_title">
                                    {data.item[4].title}
                                </div>
                                <div className="fonctionnement_text">
                                    {data.item[4].text}
                                </div>
                                {/* <ModalVideoProduit poster={data.item[3].poster} src={data.item[3].src} button_text= {data.button_text}/> */}
                            </div>
                       
                    </div>
                </div>
            </section>
        </>
    );
};
export default withRouter(Fonctionnement);
