import React, { useState, useEffect } from "react";
import { MDBAnimation } from "mdbreact";
import BigTitle from "../../Components/BigTitle";
import "./APropos.scss";
import { withRouter } from "react-router-dom";
import * as dataJSON from "../../Data/Histoire.json";
import { Element } from "react-scroll";
import Meta from "../../Components/Meta";
import Count from "../../Components/Count/count"
const APropos = (props) => {
    let lang = props.match.params.lang;

    const [data, setData] = useState(dataJSON.default[lang]);
    const[selectedPerson, setSelectedPerson]=useState(1);
    const [opacity, setOpacity]=useState({"filter":"opacity(100%)"})

    const [meta, setMeta] = useState({
        title: dataJSON.default[lang].meta.title,
        description: dataJSON.default[lang].meta.description,
        canonical: dataJSON.default[lang].meta.canonical,
        image: dataJSON.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: dataJSON.default[lang].meta.keywords,
            }

        },
    });

    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [lang]);
   
    return (
        <>
            <Meta meta={meta} />
            <MDBAnimation type="fadeIn">
                <div className="bfrexpert">
                 <div className="margeApropos" id="valeur">

                        <BigTitle
                            text={null}
                            title=""
                            span={data.ADN.bigtitle.span}
                            small={data.ADN.bigtitle.small}
                            direct="left"
                        />

                        <div className="container">
                            <div className="grid">
                                <div className="dotted_right">
                                    <h2>{data.ADN.h21.text}</h2>
                                    <p>{data.ADN.textleft1}</p>
                                   
                                    <ul>
                                        <li><strong>{data.ADN.li1leftBold}</strong> {data.ADN.lileft1}</li>
                                        <li><strong>{data.ADN.li2leftBold}</strong> {data.ADN.lileft2}</li>
                                        <li><strong>{data.ADN.li3leftBold}</strong> {data.ADN.lileft3}</li>
                                  
                                    </ul>
                                   
                                </div>
                                <div className="dotted_right">
                                    <h2>{data.ADN.h22.text}</h2>
                                    
                                        <p>{data.ADN.textcenter1}</p>
                                   
                                </div>

                                <div className="dotted_right">
                                    <h2>{data.ADN.h23.text}</h2>
                                    <ul>
                                        <li><strong>{data.ADN.li1rightBold}</strong> {data.ADN.liright1}</li>
                                        <li><strong>{data.ADN.li2rightBold}</strong> {data.ADN.liright2}</li>
                                        <li><strong>{data.ADN.li3rightBold}</strong> {data.ADN.liright3}</li>
                                  
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="margeApropos" id="expert">

                        <BigTitle
                            text={null}
                            title=""
                            span={data.experts.bigtitle.span}
                            small={data.experts.bigtitle.small}
                            direct="right"
                        />

                        <div className="container">
                            <div className="grid-2">
                                <div>
                                    
                                    {data.experts.textleft.map((p) => (
                                        <p>{p}</p>
                                    ))}
                                </div>

                                <div>
                                <video  poster={data.experts.videoright.poster} controls className="videoDelis">
                                    <source src={data.experts.videoright.video} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>
                        </div>
                </div>
                <Element name="scrolling" className="about-us_title ">
                    <BigTitle
                        text={data.about.text}
                        title={data.about.title}
                        span={data.about.span}
                        direct="left"
                    />
                </Element>
                <section className="about-us">
                    <div className="media container">
                    <div className="image">
                        <img src="https://mad-up.com/api/uploads/mad-up/originals/Map.png" alt="Map"/>
                        
                            <div className="tooltip_circle "> </div>
                            <div className="tooltip_circle1 "></div>
                            <div className="tooltip_circle2 "></div> 
                            <div className="tooltip_circle3 "> </div>
                            <div className="tooltip_circle4 "></div> 
                            <div className="tooltip_circle5 "></div>
                            <div className="tooltip_circle6 "></div>
                            <div className="tooltip_circle7 "> </div>
                            <div className="tooltip_circle8 "></div> 
                                <div className="tooltip_circle9 "></div>
                            <div className="tooltip_circle10 "> </div>
                            <div className="tooltip_circle11 "></div> 
                                <div className="tooltip_circle12"></div>
                            <div className="tooltip_circle13 "></div>
                            <div className="tooltip_circle14 "></div> 
                                <div className="tooltip_circle15 "></div> 
                            <div className="tooltip_circle16 "></div>
                            <div className="tooltip_circle17 "></div>
                    </div>
                        <div className="content">
                        {data.about.texts.length > 1 &&
                                    data.about.texts.map((text, id) => {
                                        return <p key={id}>{text}</p>;
                                    })}
                        </div>
                </div>
        
                </section>
                <section className="module counter  container " >
                    {data.about.counts.map(({number, duration, SLUG, text, paragraphe,id}) =>
                        <Count key={id} 
                        number={number}
                        duration={duration}
                        text={text}
                        paragraphe={paragraphe}
                        SLUG={`/${lang}${SLUG}`} 
                    />)}
                </section>
            </MDBAnimation>
        </>
    );
};

export default withRouter(APropos);
