import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import * as dataJSON from "../../Data/NavBar.json";

const RightNav = (props) => {
    const lang = props.match.params.lang;
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    const [data, setData] = useState(dataJSON.default[lang]);

    useEffect(() => {
        setData(dataJSON.default[lang]);
    }, [lang]);

    return (
        <Ul open={props.open} className="right-nav">

            {data.menu.map((item, id) => {
                const { SLUG, text } = item;
                if (id !== 1) {
                    if (SLUG === "/actualite")
                    {
                        return (
                            <li key={id} >
                                
                                <a
                                    href={`https://www.mad-up-academy.com/${lang}`}
                                    target="_blank"
                                    exact activeStyle={{
                                        backgroundColor: '#15acf2',
                                        color: "#fff"
                                    }}
                                >
                                    {text}
                                </a>
                            </li>
                        );
                    } 
                    else {
                        return (
                            <li key={id} >
                                <NavLink
                                    to={`/${lang}${SLUG}`}
                                    exact activeStyle={{
                                        backgroundColor: '#15acf2',
                                        color: "#fff"
                                    }}
                                    // key={id}
                                    onClick={() => {
                                        scrollToTop();
                                    }}
                                >
                                    {text}
                                </NavLink>

                            </li>
                        );
                    }
                } else {
                    return (
                        <li key={id}>
                            <NavLink
                                to={`/${lang}${SLUG}`}
                                
                                exact activeStyle={{
                                    backgroundColor: '#15acf2',
                                    color: "#fff"
                                }}
                                // key={id}
                                onClick={() => {
                                    scrollToTop();
                                }}
                            >
                                {text}
                            </NavLink>
        
                            {/* <ul className="dropdown-content" key={id}>
                                {data.sousmenuBFR.map((item, id) => {

                                    const { SLUG, text } = item;
                                    return (
                                        <li key={id}>
                                            <NavLink 
                                            exact activeStyle={{
                                                backgroundColor: '#15acf2',
                                                color: "#fff"
                                            }}
                                                to={`/${lang}${SLUG}`}

                                                key={id}
                                                onClick={() => {
                                                    scrollToTop();
                                                }}
                                            >
                                                {text}
                                            </NavLink>

                                        </li>
                                    );

                                })}
                                
                            </ul> */}
                        </li>)

                    }

            })} 


        </Ul>
    );
};

const Ul = styled.ul`
    list-style: none;
    display: flex;
    li {
        color: #ffffff;
        padding:18px 3px;
    }
    flex-flow: row nowrap;
    margin-top:15px;
    @media (max-width: 1200px) {
        padding:20px;
        flex-flow: column nowrap;
        background-color: #0D2538;
        position: fixed;
        transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
        top: 62px;
        right: 0;
        height: 100vh;
        width: 45%;
        padding-top: 3.5rem;
        transition: transform 0.3s ease-in-out;
        li {
            color: #ffffff;
            padding:18px 10px;
        }
        ul{
            color: #ffffff;
            list-style:none;
        }
        a {
            color: #ffffff;
        }
    }
    @media (max-width:768px){
        width:auto;
        top:50px;
    }
    @media (max-width: 768px) and (orientation: landscape) {
        overflow: scroll;
    }
   
`;

export default withRouter(RightNav);
