import React, { useState, useEffect } from "react";
import { MDBAnimation } from "mdbreact";
import Breadcrumb from "../../../Components/Breadcrumb";
import BigTitle from "../../BigTitle";
import Button from "../../Button";
import * as dataJSON from "../../../Data/Applications.json";
import { withRouter } from "react-router-dom";
import Meta from "../../Meta";


const Performance = (props) => {
    let lang = props.match.params.lang;
    const [data, setData] = useState(dataJSON.default.fr);
    const [meta, setMeta] = useState({
        title: dataJSON.default[lang].meta.title,
        description: dataJSON.default[lang].meta.description,
        canonical: dataJSON.default[lang].meta.canonical,
        image: dataJSON.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: dataJSON.default[lang].meta.keywords,
            }
           
        },
    }); 
  
    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [lang]);
    return (
        <>
         <Meta meta={meta} />
            <MDBAnimation type="fadeIn">
               
                <div className="container sub-application">
                    <div className="grid-2">
                        <div>
                            <div className="img">
                            <video controls width="600" poster={data.performance.video1.img}>

                                    <source src={data.performance.video1.SLUG} 
                                            type="video/webm"/>

                                    <source src={data.performance.video1.SLUG} 
                                            type="video/mp4"/>

                                    Sorry, your browser doesn't support embedded videos.
                                    </video>
                            </div>
                            
                        </div>
                        <div className="text  dotted_right">
                        <div className="title">{data.performance.title}</div>
                        <span className="big-title_small">{data.performance.bigtitle.span}</span>
                            {data.performance.text.map((p) => (
                                <p>{p}</p>
                            ))}
                        </div>
                    </div>
                   
                <div className="grid-2">
                       
                       <div className="text  dotted_right">
                       <div className="title">{data.performance.title2}</div>
                       <div className="margeP">
                           {data.performance.text2.map((p) => (
                               <p>{p}</p>
                           ))}
                       
                           
                       </div>
                       </div>
                       <div>
                           <div className="img">
                           <video controls  poster={data.performance.video2.img}>

                               <source src={data.performance.video2.SLUG} 
                                       type="video/webm"/>

                               <source src={data.performance.video2.SLUG} 
                                       type="video/mp4"/>

                               Sorry, your browser doesn't support embedded videos.
                               </video>
                           </div>
                           
                       </div>
                   </div>
                </div>


              
            </MDBAnimation>
        </>
    );
};

export default withRouter(Performance);
