import React, { useState, useEffect } from "react";
import { MDBAnimation } from "mdbreact";
//import Breadcrumb from "../Breadcrumb";
 import BigTitle from "../../BigTitle";
// import Button from "../../Button";
import * as dataJSON from "../../../Data/Applications.json";
import { withRouter } from "react-router-dom";
import Meta from "../../Meta";

const PostOp = (props) => {
    let lang = props.match.params.lang;
    const [data, setData] = useState(dataJSON.default.fr);
    const [meta, setMeta] = useState({
        title: dataJSON.default[lang].meta.title,
        description: dataJSON.default[lang].meta.description,
        canonical: dataJSON.default[lang].meta.canonical,
        image: dataJSON.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: dataJSON.default[lang].meta.keywords,
            }
           
        },
    }); 
  
    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [lang]);
   
    return (
        <>
           <Meta meta={meta} />
            <MDBAnimation type="fadeIn">
               
                <div className="container sub-application">
                    <div className="grid-2">
                        <div>
                            <div className="img">
                            <video controls width="600" poster={data.postop.video.img}>

                            <source src={data.postop.video.SLUG} 
                                    type="video/webm"/>

                            <source src={data.postop.video.SLUG} 
                                    type="video/mp4"/>

                            Sorry, your browser doesn't support embedded videos.
                            </video>
                            </div>
                           
                        </div>
                        
                        <div className="text  dotted_right">
                            
                        <div className="title">{data.postop.title}</div>
                        <span className="big-title_small">{data.postop.bigtitle.span}</span>
                            {data.postop.text.map((p) => (
                                 <p>{p}</p>
                               
                               
                              
                            ))}
                        </div>       
                    </div>
                    {/* <ModalApp text={data.postop.button.text} src={data.postop.button.SLUG} poster={data.postop.button.img}/> */}
                </div>
                {/* <BigTitle
                    text=""
                    title=""
                    span={data.postop.protocole.bigtitle.span}
                    direct="right"
                />
                <div className="container sub-application">
                    <div className="text-padding  dotted_left">
                        <p>
                            <b>{data.postop.protocole.text[0].bold} </b>
                      
                        {data.postop.protocole.text[0].normal}
                        </p>
                        <p>
                        {data.postop.protocole.text[1].normal}
                        </p>
                        
                    </div> */}
                    
                    {/* <Button
                        text={data.postop.button.text}
                        src={`/${lang}${data.postop.button.SLUG}`}
                        color="blue"
                    /> */}
                    
               
            </MDBAnimation>
        </>
    );
};

export default withRouter(PostOp);
