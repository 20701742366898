import React,{useState, useEffect} from "react";
import "./TooltipsBonhomme.scss"; 
import Button from "../Button/Button";
import ButtonExtern from "../Button/ButtonExtern";
//import {Link} from "react-router-dom";
import styled from "styled-components";

const Modal= ({ slug,title, span,text, src, legend,textSpan1,textSpan2,textSpan3 
    ,membre1,membre2,membre3,membre4,membre5, membre6, category1,category2, category3,
    firstName, lastName, email, textSubmit ,textSpan, reponse , error, errorMail, errorForm}) => {
    if (slug !== undefined) {
      //  console.log(slug);
        return (
            <>
            <h3>{title}</h3>
            <span>{span}</span>

                <img src={slug} alt={title}  className="imgBloc"/>
        
            <ButtonExtern
                color="blue"
                text={text}
                src={src}
                />
            </>
            

           
        );
    } else return false;
};

const Ul = styled.ul`
span{
    color: rgb(6, 15, 30);
    font-size: 14px;
    font-weight: 700;
    // @media(max-width:768px){
    //     font-size:12px;
       
    // }

    // @media(max-width:1100px){
    //     font-size:20px;
       
    // }
}



`;


export default Modal;
