import React, { useState, useEffect, useRef } from "react";
import { MDBAnimation } from "mdbreact";
import "./Home.scss";

import Partners from "../../Components/Partners";

import BigTitle from "../../Components/BigTitle";

import "react-multi-carousel/lib/styles.css";
import "../../Components/MultiItemCarousel/MultiItemCarousel.scss";
import Testimonial from "../../Components/Testimonial/Testimonial";
import loadable from "@loadable/component";
import { withRouter, Link } from "react-router-dom";
import * as homeData from "../../Data/Home.json";
import { Element } from "react-scroll";
import Meta from "../../Components/Meta";
import Application from "../../Components/Aplications";

const Banner = loadable(() => import("../../Components/Banner"));

const Home = (props) => {
    const lang = props.match.params.lang;
    const [data, setData] = useState(homeData.default[lang]);

    const [meta, setMeta] = useState({
        title: homeData.default[lang].meta.title,
        description: homeData.default[lang].meta.description,
        canonical: homeData.default[lang].meta.canonical,
        image: homeData.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: homeData.default[lang].meta.keywords,
            }

        },
    });

    useEffect(() => {
        setData(homeData.default[lang]);
        setMeta({
            title: homeData.default[lang].meta.title,
            description: homeData.default[lang].meta.description,
            canonical: homeData.default[lang].meta.canonical,
            keywords: homeData.default[lang].meta.keywords,
            image: homeData.default[lang].meta.image
        });
    }, [lang]);


   

    return (
        <>
            <Meta meta={meta} />
            <MDBAnimation type="fadeIn">
                <div className="scroll-container">
                    
                    <div className="margeAbout">
                        <Banner lang={lang} />
                    </div>
                    
                    <Element name="scrolling" className="about-us_title ">
                    <BigTitle
                        text={data.users.text}
                        title={data.users.title}
                        direct="left"
                    />
                </Element>
                    
                    <div className="margePartenaire">
                         <Partners data={data.partners} lang={lang} />
                    </div>

                    <Element name="scrolling" className="about-us_title ">
                        <BigTitle
                            text={data.application.text}
                            title={data.application.title}
                            direct="right"
                        />
                    </Element>
                    <div className="margeApplication" id="application">
                        <Application />
                    </div>

                </div>
                
                <section className="testimonials">
                    <h2 className="title">{data.testimonials.title}</h2>
                    <Testimonial
                        lang={lang}
                        btn={data.testimonials.button_text}
                    />
                </section>

               

            </MDBAnimation>
        </>
    );
};
export default withRouter(Home);
