import React, { useState, useEffect } from "react";
import { MDBAnimation } from "mdbreact";
import Breadcrumb from "../../Breadcrumb";
import BigTitle from "../../BigTitle";
import * as dataJSON from "../../../Data/Applications.json";
import { withRouter } from "react-router-dom";
import Meta from "../../Meta";
import "../Applications.scss";
import ModalApp from "../../ModalVideo/ModalVideo";

const Elderly = (props) => {
    
    let lang = props.match.params.lang;
    const [data, setData] = useState(dataJSON.default.fr);
    const [meta, setMeta] = useState({
        title: dataJSON.default[lang].meta.title,
        description: dataJSON.default[lang].meta.description,
        canonical: dataJSON.default[lang].meta.canonical,
        image: dataJSON.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: dataJSON.default[lang].meta.keywords,
            }
           
        },
    }); 
  
    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [lang]);
   
    return (
        <>
       <Meta meta={meta} />
            <MDBAnimation type="fadeIn">
                <div className="container sub-application">
                    <div className="grid-2">
                        <div>
                            <div className="img">
                                <video controls  poster={data.eldery.video2.img}>
                                    <source src={data.eldery.video2.SLUG} 
                                            type="video/mp4"/>

                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                            </div>
                        </div>
                        <div className="text  dotted_right">
                            <div className="title">{data.eldery.title2}</div>
                            <div className="margeP">
                                {data.eldery.text2.normal.map((p) => (
                                    <p>{p}</p>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="grid-2">
                        <div className="text  dotted_right">
                            <div className="title">{data.eldery.title}</div>
                            <span className="big-title_small">{data.eldery.bigtitle.span}</span>
                                {data.eldery.text.normal.map((p) => (
                                    <p>{p}</p>
                                ))}

                                <p>
                                    <b>{data.eldery.text.bold}</b>
                                </p>
                        </div>

                        <div>
                            <div className="img">
                            <video controls width="600" poster={data.eldery.video.img}>

                                <source src={data.eldery.video.SLUG} 
                                        type="video/mp4"/>

                                Sorry, your browser doesn't support embedded videos.
                                </video>
                            </div>
                            <blockquote ><p className="center">{data.eldery.textp}</p></blockquote>  
                            
                        </div>
                        
                    </div>
                   
                </div>
               
            </MDBAnimation>
        </>
    );
};

export default withRouter(Elderly);
