import React from "react";
import HomePage from "./Pages/Home";
import Rgpd from "./Pages/Rgpd";
import MentionsLegales from "./Pages/MentionsLegales";
import MadUpPhysio from "./Pages/MadUpPhysio";
import MadUpPro from "./Pages/MadUpPro";
import BfrExpert from "./Pages/BfrExpert";
import MadApp from "./Pages/MadAPP"
import APropos from "./Pages/APropos";
import PartnersMap from "./Pages/PartnersMap";
import Contact from "./Pages/Contact";
import Demo from "./Pages/FormDemo/DemoRequest";
import SendMail from "./Pages/TestMail/mail";
import ActivationRequest from "./Pages/FormActivateDispositif/ActivationRequest";
import Newsletter from "./Pages/NewsletterRequest/NewsletterRequest"

import { Switch, Route, withRouter , Redirect} from "react-router-dom";


const Routes = (props) => {
  
    const lang = null;
    // const url = props.match.url;
   
    return (
        <Switch>
            <Route exact path={`/:lang`}
                 render={({ match }) => {
                const { lang } = match.params;
                return <HomePage lang={lang}/>;
            }}
            />
             <Route exact path={`/:lang/rgpd`}
                 render={({ match }) => {
                const { lang } = match.params;
                return <Rgpd lang={lang}/>;
            }}
            />
            
             <Route path={`/:lang/mentions-legales/`}
                 render={({ match }) => {
                
                return <MentionsLegales lang={"fr"}/>;
            }}
            />
            <Route exact path="/:lang/mad-up-physio/" component={MadUpPhysio} />
            <Route exact path="/:lang/mad-up-pro/" component={MadUpPro} />
            <Route exact path="/:lang/mad-app/" component={MadApp} />
           
            <Route  path="/:lang/bfr-training/" 
                 render={({ match }) => {
                const { lang } = match.params;
                return <BfrExpert lang={lang}/>
                 }}
                />
               
            
            <Route exact path="/:lang/qui-sommes-nous/" component={APropos} lang={lang}/>
           
            <Route path="/:lang/ou-nous-trouver/" 
             render={({ match }) => {
                const { lang } = match.params;
                return <PartnersMap lang={lang} />}}
                />
          
            <Route  path="/:lang/contact/" 
                 render={({ match }) => {
                const { lang } = match.params;
                return <Contact lang={lang}/>
                 }}
                />

            <Route  path="/:lang/newsletter/" 
                 render={({ match }) => {
                const { lang } = match.params;
                return <Newsletter lang={lang}/>
                 }}
                />
            
            <Route  path="/:lang/activation/" 
                 render={({ match }) => {
                const { lang } = match.params;
                return <ActivationRequest lang={lang}/>
                 }}
                />

            <Route  path="/:lang/demo/" 
                 render={({ match }) => {
                const { lang } = match.params;
                return <Demo lang={lang}/>
                 }}
                />

                <Route  path="/:lang/SendMail/" 
                 render={({ match }) => {
                const { lang } = match.params;
                return <SendMail lang={lang}/>
                 }}
                />
            
            <Route
                path="/:lang/actualite/"
                render={({ match }) => {
                    const { lang } = match.params; // Récupération de la langue depuis l'URL
                    window.open(`https://www.mad-up-academy.com/${lang}`, "_blank");
                    return null;
                }}
                />
                
            <Redirect exact from="/" to="/fr/" />


        </Switch>
    );
};

export default withRouter(Routes);
