import React, { useState, useEffect } from "react";
import { MDBAnimation } from "mdbreact";
import Breadcrumb from "../../Components/Breadcrumb";
import BigTitle from "../../Components/BigTitle";
import Button from "../../Components/Button";
import { Player } from "video-react";
import "./MadUpPhysio.scss";
import { Parallax } from "react-parallax";
import * as dataJSON from "../../Data/MadUpPhysio.json";
import { withRouter, Link } from "react-router-dom";

//import Grid from "../../Components/Grid/Grid";
import Meta from "../../Components/Meta";
//import TooltipsProduit from "../../Components/Produit/TooltipsProduit";
import Fonctionnement from "../../Components/FonctionnementProduit/Fonctionnement";
import ModalProduit from "../../Components/ModalBrochureProduit/ModalProduit";

const MadUpPhysio = (props) => {
    let lang = props.match.params.lang;
    const [data, setData] = useState(dataJSON.default.fr);

    const [meta, setMeta] = useState({
        title: dataJSON.default[lang].meta.title,
        description: dataJSON.default[lang].meta.description,
        canonical: dataJSON.default[lang].meta.canonical,
        image: dataJSON.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: dataJSON.default[lang].meta.keywords,
            }
           
        },
    }); 
    const scrollToTop = () => window.scrollTo(0, 0);
    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [lang]);
    return (
        <>
         <Meta meta={meta}/>
            <MDBAnimation type="fadeIn">
                {/* <Breadcrumb title={data.breadcrumb} /> */}

                <BigTitle
                    text={data.bfrtraining.bigtitle.text}
                    title={data.bfrtraining.bigtitle.title}
                    span={data.bfrtraining.bigtitle.span}
                    direct="left"
                />

                <section className="mad-up-physio_about">
                    <div className="container">
                        <div>
                            {data.bfrtraining.textleft.map((p,id) => (
                                <p key={id}>{p}</p>
                            ))}
                        </div>
                        <div> 
                            <img src={data.bfrtraining.imgright} alt="dispositif" />
                            {/* {data.bfrtraining.textright.map((p) => (
                                <p>{p}</p>
                            ))} */}
                        </div>
                    </div>
                </section>
             
                {/* <TooltipsProduit data={data.blockProduit}/> */}
                <div >
                <BigTitle
                    text={data.lefonctionnement.bigtitle.text}
                    title={data.lefonctionnement.bigtitle.title}
                    span={data.lefonctionnement.bigtitle.span}
                    direct="right"
                />
                <Fonctionnement/>
                </div>
            </MDBAnimation>
        </>
    );
};

export default withRouter(MadUpPhysio);
