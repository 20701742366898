import React, { useState, useEffect } from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import SocialIcons from "../SocialIcons";
import { withRouter } from "react-router-dom";
import * as dataJSON from "../../Data/Footer.json";
import IconMap from "../IconMap/IconMap"
import FixedButtons from "../FixedButtons";
import { ImWhatsapp } from "react-icons/im";
import { FaEnvelopeOpen } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
//import ButtonDemo from "../ButtonDemo"
import ButtonReadMore from "../ButtonReadMore/ButtonReadMore"
const Footer = (props) => {
    const scrollToTop = () => window.scrollTo(0, 0);
    let lang = props.match.params.lang;

    const [data, setData] = useState(dataJSON.default[lang]);

    useEffect(() => {
        setData(dataJSON.default[lang]);
    }, [lang]);

    return (
        <>
            <footer>
                <div className="container">
                    <div className="grid">
                        <div>
                            <img
                                src={data.logo_full_url}
                                alt="mad-up logo"
                                className="logo"
                            /> 
                            <p>{data.text}</p> 

                             <nav className="footer-link">
                                {data.link.map((item, id) => {
                                    return (
                                        <Link
                                        to={`/${lang}${item.SLUG}`}
                                            key={id}
                                            onClick={() => {
                                                scrollToTop();
                                            }}
                                        >
                                            {item.text}
                                        </Link>
                                    );
                                })}
                            </nav>
                        </div>
                         <div>
                            {data.contact.link.map((item, id) => {
                                    return (
                                         <Link
                                        to={`/${lang}${item.SLUG}`}
                                            key={id}
                                            onClick={() => {
                                                scrollToTop();
                                            }}
                                        >
                                            <h4 className="hover">{item.text}</h4> 
                                        </Link>
                                        
                                       
                                    );
                                })}
                           
                            <nav className="contact">
                                
                           <a href="mailto:contact@mad-up.com" > <p className="hover"><FaEnvelope/>  {data.contact.email}</p></a>
                               <p className="hover"><FaPhone/>  {data.contact.num}</p> 
                                <Link to={`/${lang}${data.contact.SLUG}`}> <p className="hover" style={{textDecoration: "underline"}}> <FaEnvelopeOpen/>{data.contact.contact}</p></Link>
                            </nav>
                            <div id="socialMedia">
                            <SocialIcons />
                            </div>
                            
                        </div>
                        <div>
                            {data.newsletter.link.map((item, id) => {
                                    return (
                                         <Link
                                        to={`/${lang}${item.SLUG}`}
                                            key={id}
                                            onClick={() => {
                                                scrollToTop();
                                            }}
                                        >
                                            <h4 className="hover">{item.text}</h4> 
                                        </Link>
                                        
                                       
                                    );
                                })}
                            <nav className="newsletter"></nav>
                        </div>
                        
                        <div>
                        {/* {data.map.link.map((item, id) => {
                                    return (
                                         <Link
                                        to={`/${lang}${item.SLUG}`}
                                            key={id}
                                            onClick={() => {
                                                scrollToTop();
                                            }}
                                        >
                                            <h4 className="hover">{item.text} </h4>
                                        </Link>
                                  
                                    );
                                })} */}
                                {/* <div className="icon">
                                    {data.map.link.map((item, id) => {
                                        return (
                                            <Link
                                            to={`/${lang}${item.SLUG}`}
                                                key={id}
                                                onClick={() => {
                                                    scrollToTop();
                                                }}
                                            >
                                              
                                            </Link>
                                    
                                        );
                                       
                                    })}
                                     <IconMap src={`/${lang}${data.map.SLUG}`}/>
                                </div> */}
                           
                        </div>
                    </div>
                    <div className="copyright">
                        <p>{data.copyriting.text}</p>
                        <p>
                            
                                {data.copyriting.autor}
                                <b>  MAD-UP SAS</b>
                        </p>
                    </div>
                </div>
            </footer>
           
           {/* <FixedButtons/> */}
           <ButtonReadMore/>
           
        </>
    );
};

export default withRouter(Footer);
