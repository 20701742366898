import React, { useState, useEffect } from "react";
import { MDBAnimation } from "mdbreact";
import "./BfrExpert.scss";
import TooltipsBonhomme from "../../Components/Bonhomme/TooltipsBonhomme";
import * as dataJSON from "../../Data/BfrExpert.json";
import { withRouter, useLocation } from "react-router-dom";
import Meta from "../../Components/Meta";
import BigTitle from "../../Components/BigTitle";

import Rehub from "../../Components/BfrExpert/Rehub/Rehub";
import PostOp from "../../Components/BfrExpert/PostOp/PostOp";
import PreOp from "../../Components/BfrExpert/PreOp/PreOp";
import Elderly from "../../Components/BfrExpert/Elderly";
import Performance from "../../Components/BfrExpert/Performance";

document.addEventListener("DOMContentLoaded", function () {
    console.log('scroll');
    if (window.location.hash) {
        let target = document.querySelector(window.location.hash);
        
        console.log('scroll' . target);
        if (target) {
            setTimeout(() => {
                target.scrollIntoView({ behavior: "smooth" });
            }, 500); // Petit délai pour s'assurer que tout est bien chargé
        }
    }
});

const BfrExpert = (props) => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace("#", "");
            const element = document.getElementById(id);
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: "smooth" });
                }, 100);
            }
        }
    }, [location]);

    let lang = props.match.params.lang;
    const [data, setData] = useState(dataJSON.default.fr);

    const [meta, setMeta] = useState({
        title: dataJSON.default[lang].meta.title,
        description: dataJSON.default[lang].meta.description,
        canonical: dataJSON.default[lang].meta.canonical,
        image: dataJSON.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: dataJSON.default[lang].meta.keywords,
            }

        },
    });

    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [props, lang]);
    return (
        <>
            <Meta meta={meta} />
            <MDBAnimation type="fadeIn">
            <TooltipsBonhomme data={data.tooltips}/>  
            <div className="long">
                    <BigTitle
                        text={null}
                        title={data.restriction.bigtitle.title}
                        span={data.restriction.bigtitle.span}
                        direct="left"
                    />
                </div>
                <div className="container">
                    <div className="grid-2 first-color-blue">
                        <div>
                        {data.restriction.textleft.map((p,id) => (
                            <p key={id}>{p}</p>
                        ))}
                        </div>
                        <div className="dotted_right">
                        {data.restriction.textright.map((p,id) => (
                            <p key={id}>{p}</p>
                        ))}
                        </div>
                    </div>
                </div>       

                <BigTitle
                    text={null}
                    title={data.reeducation.bigtitle.title}
                    span={data.reeducation.bigtitle.span}
                    direct="right"
                />
                <div id="preop"> <PreOp/></div>
                <div id="postop"> <PostOp/></div>
                <div id="rehub"> <Rehub/></div>
            
                <BigTitle
                    text={null}
                    title={data.elderly.bigtitle.title}
                    span={data.elderly.bigtitle.span}
                    direct="left"
                />
                <div id="elderly"> <Elderly/></div>
                <BigTitle
                    text={null}
                    title={data.performance.bigtitle.title}
                    span={data.performance.bigtitle.span}
                    direct="right"
                />
                <div id="performance"> <Performance/></div>
            </MDBAnimation>
        </>
    );
};

export default withRouter(BfrExpert);

